<template>
    <div class="page bg-white" id="reorganize">
        <el-row :gutter="20">
            <el-col :span="6">
                <div class="lump">
                    <div class="catalogueBox">
                        <div class="flex_l_c">
                            <el-row :gutter="20">
                                <el-col :span="19">
                                    <el-input maxlength="66" class="flex1" v-model="catalogueSrh" clearable placeholder="请输入模板名称" size="small"></el-input>
                                </el-col>
                                <el-col :span="5">
                                    <el-button size="mini" type="primary" icon="el-icon-plus" circle  @click="addTemplate"></el-button>
                                </el-col>
                            </el-row>
                        </div>
                        <el-table v-loading="loading2"
                                :data="catalogueList"
                                ref="catalogueTable"
                                size="small"
                                height="calc(100vh - 268px)"
                                class="table" highlight-current-row
                                @current-change="handleCurrentChange">
                            <el-table-column prop="templateName" label="模板名称" show-overflow-tooltip/>
                            <el-table-column fixed="right" label="操作" width="150">
                                <template slot-scope="scope">
                                    <el-button size="mini" type="primary" plain icon="el-icon-copy-document" @click="copy(scope.row.id)"></el-button>
                                    <el-button size="mini" type="primary" plain icon="el-icon-edit" @click="edit(scope.row)"></el-button>
                                    <el-button size="mini" type="danger" plain icon="el-icon-delete" @click="delTemplate(scope.row.id)"></el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                        <el-pagination
                                @size-change="sizeChangeHandle"
                                @current-change="currentChangeHandle"
                                :current-page="pageNo"
                                :page-sizes="[10, 20, 50, 100]"
                                :page-size="pageSize"
                                :total="total"
                                background
                                layout="prev, pager, next">
                        </el-pagination>
                    </div>
                </div>
            </el-col>
            <el-col :span="18">
                <div class="lump">
                    <el-row class="headBox">
                        <el-col :span="10">
                            <div class="flex_l_c">
                                <el-input maxlength="66" class="flex1 m_r1" v-model="fileSrh" placeholder="请输入目录名" size="small" clearable></el-input>
                                <el-button icon="el-icon-search" type="primary" size="small" @click="searchList">查询</el-button>
                                <el-button @click="resetSearch()" size="small" icon="el-icon-refresh-right">重置</el-button>
                            </div>
                        </el-col>
                        <el-col :span="14" class="text_right">
                            <el-button icon="el-icon-plus" size="small" type="primary" :disabled="templateId==''" @click="view('add',templateId)">新建</el-button>
                            <el-button type="danger" size="small" icon="el-icon-delete" @click="del()" :disabled="dataListSelections.length <= 0" plain>删除</el-button>
                        </el-col>
                    </el-row>
                    <div class="catalogueBox">
                        <el-table
                                :data="fileList"
                                size="small"
                                @selection-change="selectionChangeHandle"
                                v-loading="loading"
                                height="calc(100vh - 290px)"
                                class="table">
                            <el-table-column type="selection" width="50"/>
                            <el-table-column prop="sort" label="排序" width="110" sortable></el-table-column>
                            <el-table-column prop="catalogNumber" sortable label="目录编号" show-overflow-tooltip/>
                            <el-table-column prop="catalogName" label="目录名" show-overflow-tooltip/>
                            <el-table-column sortable prop="postStatus" label="发布状态" show-overflow-tooltip>
                                <template slot-scope="scope">
                                    <el-tag :type="scope.row.postStatus == 0?'success':'danger'" >{{scope.row.postStatus == 0 ? '已发布' : '未发布'}}</el-tag>
                                </template>
                            </el-table-column>
                            <el-table-column fixed="right" label="操作" width="200">
                                <template slot-scope="scope">
                                    <el-button size="mini" type="primary" plain @click="view('edit', scope.row)">编辑</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                        <el-pagination
                                @size-change="sizeChangeHandle2"
                                @current-change="currentChangeHandle2"
                                :current-page="pageNo2"
                                :page-sizes="[10, 20, 50, 100]"
                                :page-size="pageSize2"
                                :total="total2"
                                background
                                layout="total, prev, pager, next, sizes">
                        </el-pagination>
                    </div>
                </div>
            </el-col>
        </el-row>
        <!--新建、编辑目录-->
        <AddCatalogueForm ref="addCatalogueForm" @refreshDataList="getFileList"></AddCatalogueForm>
        <!--新建模板-->
        <AddTemplate ref="addTemplate" @refreshDataList="getCatalogue"></AddTemplate>
    </div>
</template>

<script>
    import AddCatalogueForm from './addCatalogueForm' // 新建目录弹窗
    import AddTemplate from './addTemplate' // 新建模板弹窗

    export default {
        components: {AddCatalogueForm, AddTemplate},
        data() {
            return {
                visibleLog: false,
                // 展览
                catalogueSrh: '',
                catalogueList: [],
                pageNo: 1,
                pageSize: 10,
                total: 0,
                loading2: false,
                // 文物清单
                fileSrh: '',
                fileList: [],
                dataListSelections: [],
                loading: false,
                pageNo2: 1,
                pageSize2: 10,
                total2: 0,
                templateId: "",
            }
        },
        watch: {
            // 实时输入查询目录模板
            catalogueSrh() {
                this.search();
            },
        },
        mounted() {
            this.getCatalogue();
            // this.getFileList();
        },
        methods: {
            // 新建、编辑
            view(method, row) {
                if(method =="add" && this.templateId == ""){
                    this.$message.error("请选择模板")
                    return false
                }
                this.$refs.addCatalogueForm.init(method, row)
            },
            // 目录表格多选
            selectionChangeHandle(val) {
                this.dataListSelections = val
            },
            search() {
                this.templateId = ""
                this.pageNo = 1;
                this.pageSize = 10;
                this.total = 0;
                this.getCatalogue();
                this.fileList = [];
            },
            // 查询目录模板
            getCatalogue() {
                this.loading2 = true
                this.$axios(this.api.zlzs.catalogTemplateList, {
                    'templateName': this.catalogueSrh,
                    "templateType":0,
                    'pageNo': this.pageNo,
                    'pageSize': this.pageSize,
                }, 'get').then(data => {
                    if (data && data.status) {
                        this.catalogueList = data.data.records
                        this.total = parseInt(data.data.total)
                        this.loading2 = false
                    }
                })
            },
            // 重置
            resetSearch() {
                this.fileSrh = '';
                this.getFileList()
            },
            // 点击目录模板行
            handleCurrentChange(val) {
                this.$refs.catalogueTable.setCurrentRow(val);
                this.templateId = val.id
                this.searchList()
            },
            searchList() {
                this.pageNo2 = 1;
                this.getFileList();
            },
            // 查询目录
            getFileList() {
                if(this.templateId !== ''){
                    this.loading = true
                    this.$axios(this.api.zlzs.SysCatalogTemplateDataList, {
                        'templateId': this.templateId,
                        "catalogName": this.fileSrh,
                        'pageNo': this.pageNo2,
                        'pageSize': this.pageSize2,
                    }, 'get').then(data => {
                        if (data && data.status) {
                            this.fileList = data.data.records
                            this.total2 = parseInt(data.data.total)
                            this.loading = false
                        }
                    })
                }
            },
            // 新建模板
            addTemplate() {
                this.$refs.addTemplate.init("add")
            },
            //复制模板数据
            copy(id){
                this.$refs.addTemplate.init("copy",id)
            },
            edit(row){
                this.$refs.addTemplate.init("edit",row)
            },
            // 删除模板
            delTemplate(id) {
                this.$confirm(`确定删除所选项吗?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$axios(this.api.zlzs.catalogTemplateRemoveById, {
                        'id': id,
                    }, 'delete').then(data => {
                        if (data && data.status) {
                            this.$message.success(data.msg)
                            this.getCatalogue()
                            this.searchList()
                        }else{
                            this.$message.error(data.msg)
                        }
                    })
                })
            },
            //删除模板数据
            del(id) {
                // 批量删除多个id用逗号隔开
                let ids = id || this.dataListSelections.map(item => {
                    return item.id
                }).join(',')
                this.$confirm(`确定删除所选项吗?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$axios(this.api.zlzs.SysCatalogTemplateDataRemove, {
                        'ids': ids,
                    }, 'delete').then(data => {
                        if(data && data.status){
                            this.$message.success(data.msg)
                            if(ids.split(",").length==this.fileList.length){
                                this.pageNo2=1
                            }
                            this.getFileList()
                        }else{
                            this.$message.error(data.msg)
                        }
                    })
                })
            },
            // 展览每页数
            sizeChangeHandle(val) {
                this.pageSize = val;
                this.pageNo = 1;
                this.getCatalogue();
            },
            // 展览当前页
            currentChangeHandle(val) {
                this.pageNo = val;
                this.getCatalogue();
            },
            // 文物清单每页数
            sizeChangeHandle2(val) {
                this.pageSize2 = val;
                this.pageNo2 = 1;
                this.getFileList();
            },
            // 文物清单当前页
            currentChangeHandle2(val) {
                this.pageNo2 = val;
                this.getFileList();
            },
        }
    }
</script>

<style scoped>

</style>
